<template>
  <!--话术配置  模块-->
  <div>
    <el-card shadow="never">
      <div class="where">
        <el-form
            :model="where"
            label-width="60px"
            class="ele-form-search"
            @submit.native.prevent>
          <el-row :gutter="15">
            <el-col :lg="21" :md="12">
              <el-form-item label="服务商:">
                <el-select
                    style="width: 300px !important;"
                    clearable
                    v-model="where.gender"
                    placeholder="请选择服务商"
                    class="ele-fluid">
                  <el-option label="服务商1" value="1"/>
                  <el-option label="服务商1" value="2"/>
                  <el-option label="服务商 1" value="3"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!--H5链接说明文字-->
      <div class="ageing" style="margin-top: 30px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              H5链接说明文字
            </div>
          </div>
        </div>
        <!-- H5链接说明文字 -->
        <div style="margin-top: 15px;">
          <el-input
              clearable
              :autosize="{ minRows: 5, maxRows: 5}"
              type="textarea"
              :rows="2"
              placeholder="对应到订单的注意事项，用户H5中客户服务说明"
              v-model="textarea">
          </el-input>
        </div>
      </div>
    </el-card>

    <el-card shadow="never">
      <!--困境救援话术-->
      <div class="ageing">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              困境救援话术
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="Add()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle" >添加</span>
              </el-button>
            </div>
          </div>
        </div>
        <!-- 困境救援话术 -->
        <div style="margin-top: 15px;" class="zidingyifuhexing">
          <div style="display: flex; align-items: center; margin-bottom: 10px;" v-for="(item,index) in difficulty" :key="index">
            <div style="font-size: 20px;font-weight: 700;text-align: left;color: #050505; margin-right: 10px;">{{item.serialNumber}}.</div>
            <div style="width: 100%;">
              <div style="display: flex;">
                <el-input placeholder="请输入内容" v-model="item.ask" style="margin-right: 20px;"></el-input>
                <el-input placeholder="请输入内容" v-model="item.content">
                  <template slot="append">
                    <div @click="del(item,index)">
                      删除
                    </div>
                  </template>
                </el-input>
              </div>
            </div>
          </div>

        </div>
      </div>
    </el-card>

    <el-card shadow="never">
      <!--搭电话术-->
      <div class="ageing">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              搭电话术
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="groundingAdd()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle" >添加</span>
              </el-button>
            </div>
          </div>
        </div>
        <!-- 搭电话术 -->
        <div style="margin-top: 15px;" class="zidingyifuhexing">
          <div style="display: flex; align-items: center; margin-bottom: 10px;" v-for="(item,index) in grounding" :key="index">
            <div style="font-size: 20px;font-weight: 700;text-align: left;color: #050505; margin-right: 10px;">{{item.serialNumber}}.</div>
            <div style="width: 100%;">
              <div style="display: flex;">
                <el-input placeholder="请输入内容" v-model="item.ask" style="margin-right: 20px;"></el-input>
                <el-input placeholder="请输入内容" v-model="item.content">
                  <template slot="append">
                    <div @click="del(item,index)">
                      删除
                    </div>
                  </template>
                </el-input>
              </div>
            </div>
          </div>

        </div>
      </div>
    </el-card>

    <el-card shadow="never">
      <!--拖车话术-->
      <div class="ageing">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              拖车话术
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="trailerAdd()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle" >添加</span>
              </el-button>
            </div>
          </div>
        </div>
        <!-- 拖车话术 -->
        <div style="margin-top: 15px;" class="zidingyifuhexing">
          <div style="display: flex; align-items: center; margin-bottom: 10px;" v-for="(item,index) in trailer" :key="index">
            <div style="font-size: 20px;font-weight: 700;text-align: left;color: #050505; margin-right: 10px;">{{item.serialNumber}}.</div>
            <div style="width: 100%;">
              <div style="display: flex;">
                <el-input placeholder="请输入内容" v-model="item.ask" style="margin-right: 20px;"></el-input>
                <el-input placeholder="请输入内容" v-model="item.content">
                  <template slot="append">
                    <div @click="del(item,index)">
                      删除
                    </div>
                  </template>
                </el-input>
              </div>
            </div>
          </div>

        </div>
      </div>
    </el-card>

    <!--司机端话术-->
    <el-card shadow="never">
      <!--H5链接说明文字-->
      <div class="ageing">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              司机端话术
            </div>
          </div>
        </div>
        <!-- 司机端话术 -->
        <div style="margin-top: 15px;">
          <el-input
              clearable
              :autosize="{ minRows: 5, maxRows: 5}"
              type="textarea"
              :rows="2"
              placeholder="司机执行端,接单后弹出话术,界面上有拨打电话"
              v-model="textarea1">
          </el-input>
        </div>
      </div>
    </el-card>

    <!--按钮-->
    <div class="card_bottom">
      <el-button style="width: 200px !important;">取消</el-button>
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;">提交</el-button>
    </div>

  </div>
</template>

<script>

export default {
  components:{

  },
  data(){
    return{
      //条件
      where:{},
      // H5链接说明文字
      textarea:'',
      // 困难话术数组
      difficulty:[
        {
          serialNumber:1,
          ask:'',
          content:'',
        },
        {
          serialNumber:2,
          ask:'',
          content:'',
        },
        {
          serialNumber:3,
          ask:'',
          content:'',
        }
      ],

      // 搭电话术数组
      grounding:[
        {
          serialNumber:1,
          content:'',
          ask:'',
        },
        {
          serialNumber:2,
          content:'',
          ask:'',
        },
        {
          serialNumber:3,
          content:'',
          ask:'',
        }
      ],

      // 拖车话术
      trailer:[
        {
          serialNumber:1,
          content:'',
          ask:'',
        },
        {
          serialNumber:2,
          content:'',
          ask:'',
        },
        {
          serialNumber:3,
          content:'',
          ask:'',
        }
      ],

      //司机端话术
      textarea1:'',

    }
  },

  methods:{
    // 困难话术添加事件
    Add(){
      // 获取最后一条数据
      let length = this.difficulty.length - 1;
      // 获取最后一条数据的序号
      let serialNumber_length = this.difficulty[length].serialNumber;
      console.log(serialNumber_length)
      // 添加数据
      this.difficulty.push({
        serialNumber: serialNumber_length + 1,
        content:''
      })
    },
    // 困难话术删除事件
    del(item,index){
      console.log(item);
      console.log(index);
      let that = this;
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        that.difficulty.splice(index, 1)
      }, "确定", 'warning');
    },

    // 搭电话术添加事件
    groundingAdd(){
      // 获取最后一条数据
      let length = this.grounding.length - 1;
      // 获取最后一条数据的序号
      let serialNumber_length = this.grounding[length].serialNumber;
      console.log(serialNumber_length)
      // 添加数据
      this.grounding.push({
        serialNumber: serialNumber_length + 1,
        content:''
      })
    },
    // 搭电话术删除事件
    groundingdel(item,index){
      console.log(item);
      console.log(index);
      let that = this;
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        that.grounding.splice(index, 1)
      }, "确定", 'warning');
    },

    //拖车话术添加事件
    trailerAdd(){
      // 获取最后一条数据
      let length = this.trailer.length - 1;
      // 获取最后一条数据的序号
      let serialNumber_length = this.trailer[length].serialNumber;
      console.log(serialNumber_length)
      // 添加数据
      this.trailer.push({
        serialNumber: serialNumber_length + 1,
        content:''
      })
    },
    // 拖车话术删除事件
    trailerdel(item,index){
      console.log(item);
      console.log(index);
      let that = this;
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        that.trailer.splice(index, 1)
      }, "确定", 'warning');
    },

  }

}
</script>

<style lang="scss" scoped>
.ageing{
  .ageing_b{
    padding-top: 15px;
    padding-bottom: 15px;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    margin-top: 15px;
  }

  .ageing_b_text_l{
    display: inline-block;
    width: 160px;
    font-size: 16px;
    font-weight: 400;
    color: #868792;
    text-align: right;
  }
  .ageing_b_text_r{
    margin-left: 15px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #050505;
  }
}

// 左右并行
.parallel:after{
  content: '';
  clear: both;
  display: block;
}
.parallel{
  .left{
    width: 30%;
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #050505;
    margin-top: 5px;
  }
  .right{
    width: 50%;
    float: left;
    margin-left: 15px;
  }
}

.right_img{
  width: 300px;
  height: 280px;
  padding: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


</style>
