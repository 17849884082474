<!-- 备注回复弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'请选择服务商':'请选择服务商'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">

      <el-row style="display: flex;align-items: flex-start;flex-direction: row;flex-wrap: nowrap;justify-content: flex-start;">
        <el-form-item label="服务商">
          <el-input
              style="width: 300px;"
              clearable
              :maxlength="20"
              v-model="form.nickname"
              placeholder="请输入"/>
        </el-form-item>
        <el-button class="custom-button" style="background: #3F4157 !important; border-color: #3F4157!important; color: #FFFFFF !important;height: 36px; line-height: 13px;">
          <!--el-icon-devops 是自己命名的-->
          <i class="el-icon-sousuo" />
          <span style="vertical-align: middle">搜索</span>
        </el-button>
      </el-row>

      <el-row>
        <span style="font-size: 14px;font-weight: 400;color: #a8a8a8;">为您查询到以下服务商：</span>
      </el-row>

      <el-row>
        <div style="background: #f7f8fa;border-radius: 6px;padding: 20px;">
          <el-radio @click.native="isSendMessage" style="margin: 8px;" v-model="form.radio" label="1">服务商A，注册地：重庆市酉阳县钟多街道何家坝路261号，联系电话：15725604521</el-radio>
          <el-radio @click.native="isSendMessage" style="margin: 8px;" v-model="form.radio" label="2">服务商B，注册地：重庆市酉阳县何家坝路261号，联系电话：15725604521</el-radio>
          <el-radio @click.native="isSendMessage" style="margin: 8px;" v-model="form.radio" label="3">服务商C，注册地：重庆市钟多街道何家坝路261号，联系电话：15725604521</el-radio>
        </div>

        <div style="margin-top: 20px;">
          <el-form-item label="合同开始日期">
            <el-date-picker
                v-model="form.value1"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="合同结束日期">
            <el-date-picker
                v-model="form.value1"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="请上传合作协议" label-width="110px">
            <div class="upload_bg_shouchi">
              <el-upload
                  :limit="1"
                  :class="{hide_box: businessLicenseupload_btn}"
                  :on-change="businessLicensechange"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  list-type="picture-card"
                  :on-preview="businessLicensePreview"
                  :on-remove="businessLicensehandleRemove">
                <!--<i class="el-icon-plus"></i>-->
                <img style="margin-top: 30px;" src="@/assets/images/home/upload_anniu.png" alt="">
                <div style="font-size: 16px;font-weight: 500;color: #ff9b05;margin-top: 15px;">上传合作协议</div>
              </el-upload>
              <el-dialog :visible.sync="businessLicensedialogVisible">
                <img width="100%" :src="businessLicensedialogImageUrl" alt="">
              </el-dialog>
            </div>
          </el-form-item>
        </div>

      </el-row>


    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //合作协议
      businessLicenseupload_btn:false,
      businessLicensedialogVisible:false,
      businessLicensedialogImageUrl:'',

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    isSendMessage(){
      console.log(this.form)
    },

    // 合作协议
    businessLicensePreview(file){
      this.businessLicensedialogImageUrl = file.url;
      this.businessLicensedialogVisible = true;
    },
    businessLicensehandleRemove(file,fileList){
      console.log(file, fileList);
      this.businessLicenseupload_btn = false;
    },
    businessLicensechange(){
      this.businessLicenseupload_btn = true;
    },


  }
}
</script>

<style scoped lang="scss">
  .hide_box /deep/ .el-upload--picture-card {
    display: none;
  }
</style>
